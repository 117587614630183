export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "#ERR_NETWORK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서버와의 통신에 문제가 있습니다."])},
  "#ERR_SERVER_DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서버 처리중에 문제가 발생하였습니다."])},
  "#ERR_CANNOT_FARM_ENTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["농장 점검중입니다."])},
  "#ERR_NEED_RECONNECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서버 설정값이 변경되었습니다.  \n재접속이 필요합니다."])},
  "#ERR_SERVER_BLOCK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 기능은 점검중입니다."])},
  "#ERR_NEED_KAIKAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikas 지갑이 필요합니다."])},
  "#ERR_WALLET_CHANGED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑이 변경되었습니다."])},
  "#ERR_CONTRACT_NETWORK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑의 네트워크를 확인해주세요."])},
  "#ERR_DOUM_DESIGNER_ONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["창작자 지갑이 필요합니다."])},
  "#ERR_REGISTERED_WALLET_ONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 지갑이 필요합니다."])},
  "#ERR_NOT_FOUND_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색 결과를 찾지 못했습니다."])},
  "#ERR_PD_EGG_NEED_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알 상태이더라도 완료까지 시간이 필요합니다."])},
  "#ERR_PD_TAKE_DAK_STATUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알의 표시 상태에 문제가 있습니다."])},
  "#ERR_PD_MINT_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["민팅 요청이 실패하였습니다"])},
  "#ERR_PD_MINT_AFTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["민팅은 완료되었으나 블록체인에 반영까진 시간이 걸리는것 같습니다.\n잠시후 다시 접속해주세요."])},
  "#ERR_NEED_PD_NFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["심는닭 소지 후 방문해 주세요"])},
  "#ERR_DISCORD_NOT_FOUND_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터를 찾지 못했습니다. 디스코드 지갑 연동 작업을 처음부터 다시 진행해주세요."])},
  "#LOADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["불러오는중입니다."])},
  "#LOADING_WAIT_WALLET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑인증을 기다리는중입니다."])},
  "#NEW_VERSION_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새버전이 있습니다. 페이지를 새로 고쳐주세요"])},
  "#MENU_HELLO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사말"])},
  "#MENU_HELLO_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안녕하세요"])},
  "#MENU_HELLO_TITLE_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안녕하세요 SIDNFT 입니다."])},
  "#MENU_PDAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["심는닭"])},
  "#MENU_PDAK_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["심는닭"])},
  "#MENU_PDAK_VIEWER_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["심는닭 뷰어"])},
  "#MENU_PDAK_FARM_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["심는닭 농장"])},
  "#MENU_DOUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지원"])},
  "#MENU_DOUM_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["창작자 지원"])},
  "#RTZ_VIEW_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RocketZett 뷰어"])},
  "#RTZ_WORK_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RocketZett 작업실"])},
  "#RTZ_NFT_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RocketZett NFT"])},
  "#OPENSEA_TOOLS_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오픈씨용 툴"])},
  "#MY_TOOLS_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마이 툴"])},
  "#TEST_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테스트"])},
  "#DISCORD_OAUTH2_VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["디스코드"])},
  "#DISCORD_OAUTH2_VIEW_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["디스코드 지갑 연결"])},
  "#INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보"])},
  "#WALLET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑"])},
  "#HATCH_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부화 시간"])},
  "#HATCH_FEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부화 수수료"])},
  "#OWNED_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보유 리스트"])},
  "#FUNCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기능"])},
  "#BG_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배경"])},
  "#HATCH_COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부화 완료"])},
  "#CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확정"])},
  "#BACK_TO_EGG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알로 교환"])},
  "#TAKE_EGG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알 받기"])},
  "#FEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수수료"])},
  "#EXTERNAL_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["외부 링크"])},
  "#TIP_HATCH_EGG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알에서 닭이 나옵니다. 닭이 랜덤한 속성을 가집니다. 팟은 유지됩니다."])},
  "#TIP_CONFIRM_DAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확정후엔 닭이 알을 만들기 시작합니다. 낳을 수 있는 알이 남아있다면 말이죠."])},
  "#TIP_BACK_TO_EGG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닭이 마음에 들지 않는 경우 다시 알로 되돌아 갈 수 있습니다."])},
  "#TIP_TAKE_EGG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["민팅 후 새로운 알을 받습니다."])},
  "#STATUS_NO_EGG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알 없음"])},
  "#STATUS_HATCHING": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["부화중입니다, ", _interpolate(_named("ago")), " 남음"])},
  "#STATUS_HATCHING_COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부화가 완료되었습니다."])},
  "#STATUS_NEED_COMPIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결정이 필요합니다."])},
  "#STATUS_TAKING_EGG": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["알을 만드는 중입니다, ", _interpolate(_named("ago")), " 남음"])},
  "#STATUS_TAKE_EGG_COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알을 받으세요"])},
  "#LANG_KO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한"])},
  "#LANG_EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
  "#NUMBER_ONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["번호입력"])},
  "#ATTRIBUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["속성"])},
  "#BTN_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])},
  "#BTN_EXIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나가기"])},
  "#NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림"])},
  "#WALLET_LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
  "#WALLET_LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])},
  "#HELLO_MSG_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# SIDNFT 는 NFT, 게임제작 관련 활동을 합니다.\n\n컨텐츠를 재정비 중입니다."])},
  "#RELATED_NEWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관련 뉴스"])},
  "#TAG_PRODUCTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생산성"])},
  "#TAG_RECYCLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재활용"])},
  "#TAG_VIRTUALIZATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가상화"])},
  "#PDAK_MSG_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["## 심는닭은 화분과 닭이 조합된 NFT 입니다.\n\n메타버스 환경에서 배경을 장식하는 소품으로 사용되는 것을 목적으로 개발중입니다.  \n<br/>\n화분은 환경을 상징합니다. 닭은 메타버스 속에 동물을 상징합니다. 현실세계에서 동물을 키우기 어려운 경우에도 안전하게 잘 자랄 것입니다."])},
  "#PDAK_ENTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입장하기  \n<sub>보유자만 가능</sub>  \n<sub>kaikas 필요</sub>"])},
  "#PDAK_MSG_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["## 알 낳는 NFT\n\n심는닭은 알을 낳아 보유 개수를 증가시킵니다. 추가로 발급받은 NFT 역시 소유자의 것이며 OpenSea 를 통해 거래하실 수 있습니다.  \n<br/>\n알에서 부화한 심는닭은 부모의 최대 알 수 보다 1개 줄어듭니다.  \n<br/>\n개발 초기에는 저렴하게 공급하게되며 알 부화를 위한 수수료도 저렴합니다. 개발이 완료됨에 따라 수수료가 증가하며 심는닭의 증가량을 조정하게됩니다."])},
  "#PDAK_MSG_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["## 업그레이드 예정\n\n현재는 개방을 위해 직접 그렸습니다.\n추후 디자이너를 통해 그림을 단계에 걸쳐 업그레이드 할 예정입니다.\n업데이트 적용후에는 심는닭 보유자는 [**입장하기**] 버튼을 이용해 자신의 NFT 를 업그레이드 가능합니다. 물론 구 버전도 사용 가능합니다.  \n<br/>\n+ 현재 - 32x32 이미지로 제공  \n+ 미래 - 더 높은 해상도 적용  \n+ 더 미래 - 3D 화 진행"])},
  "#PDAK_MSG_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["## 설명 편집 가능\n\nNFT의 설명을 편집할 수 있습니다.\n메세지를 남겨 OpenSea 에서 표현해보세요."])},
  "#PDAK_MSG_WEBGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["### 기술 데모\n\n웹브라우저에서 플레이 가능한 데모 입니다.  \n  \n실제 출시될 게임과는관련이 없습니다.\n구현가능한 기능을 테스트해보기 위한 데모 입니다."])},
  "#PDAK_MSG_WEBGAM_KABOOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D 런 게임  \n(모바일 기기및 iOS 가능합니다.)  \n달리고 코인을 모으세요 "])},
  "#PDAK_MSG_WEBGAM_UNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D 미니게임  \n(PC 데스크탑에서 가능하빈다.)\n화분으로 닭을 받으세요"])},
  "#DOUM_H1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# 창작자를 지원합니다. (계획중)"])},
  "#DOUM_ENTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입장하기 <sub>(등록자만 가능)</sub>"])},
  "#DOUM_MSG_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT 의 핵심은 창작자에게 창작물의 가치가 직접적으로 가는 것입니다.  스마트컨트랙트나 코딩을 배우는 것은 아라고 생각합니다.\n<br/>\nNFT로 창작활동을 하고 싶지만 코딩 배우기 힘드신 분들은  제가 도와드리겠습니다.\n창작활동도 바쁜데 코딩 배우랴.... 시간이 낭비될 수 있습니다.  \n<br/>\n추가적으로 스마트컨트랙트의 개발 환경은 혼돈의 시기 입니다.\n개발환경이 업데이트 됨에 따라 배워야하는 시간이 낭비되는 문제도 생각해보셔야합니다.\n<br/>\n개발환경은 안정화 이후에 시작하면 더 쉽게 배울 수 있으니까요.\n<br/>\n### 개발중인 내용\n+ 일반적인 NFT\n+ 조합형 NFT"])},
  "#PDVIEW_PARENT_EGG": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["**#", _interpolate(_named("uid")), "** 의 ", _interpolate(_named("egg_uid")), " 번째 알"])},
  "#TOOL_CONVERT_DATE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오픈씨: 날짜를 숫자로 변환하기"])},
  "#TOOL_CONVERT_DATE_DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenSea 의 속성값을 사용할때 날짜를\n사용하는 경우 숫자로 변환이 필요합니다.\n<br/>\nDATE 에 입력하면 VALUE 가 변환됩니다."])},
  "#AD_HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사이트 유지를 위한 광고입니다."])}
}