<template>
  <div>
    <div class="flex justify-center">
      <div class="space-y-2 p-2 m-2 w-3/4 bg-white rounded-xl">
        <h1 class="flex justify-center">작업중입니다.</h1>
        <div class="flex justify-center">
          <div class="space-y-2">
            <p>2022년 메타버스는 버블로 끝나버린거 같군요.</p>
            <p>정상적인 동작을 위해 웹 + nft + 모바일앱을 연동할 예정입니다.</p>
            <p>잠시만 기다려주세요</p>
            <p class="text-xs">2022-12-08 작성</p>
            <div class="flex justify-center">
              <img src="@/assets/potdak/pot-chicken-no-bone.png" />
            </div>
          </div>
        </div>
        >
      </div>
    </div>
  </div>
</template>

<script>
// import { defineComponent } from "@vue/composition-api";

export default {
  setup() {
    document.body.className = "body-bg-1";
  },
};
</script>
