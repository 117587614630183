import { createApp } from "vue";
// import App from "./App.vue";
import App from "./AppWait.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import i18n from "./i18n";
import { marked } from "marked";

if (process.env.VUE_APP_MODE === undefined) {
  console.error("need .env VUE_APP_MODE");
}

// https://anys4udoc.readthedocs.io/en/latest/attach/doc-markdown.html
const markedMixin = {
  methods: {
    md: function (input) {
      return marked(input);
    },
  },
};

createApp(App)
  .mixin(markedMixin)
  .use(i18n)
  .use(store)
  .use(router)
  .mount("#app");
