import { createStore } from "vuex";
import ax from "axios";
// import util from "util";
import moment from "moment";
import Caver from "caver-js";
import { marked } from "marked";

let store = null;
let HEARTBEAT_SEC = 60;
let HEARTBEAT_CHECK_MS = 10 * 1000;
let DISPLAY_URL = process.env.VUE_APP_POST_URL;
let IS_PRODUCTION = true;
let GET_URL = process.env.VUE_APP_GET_URL;
if (process.env.VUE_APP_MODE == "PUBLIC") {
  console.log = function () {};
  HEARTBEAT_SEC = 60 * 15;
  HEARTBEAT_CHECK_MS = 60 * 1000;
  DISPLAY_URL = "";
  IS_PRODUCTION = true;
}

const POST_SIMPLE_URL = process.env.VUE_APP_POST_URL + "/simple";
const VERSION = Number(process.env.VUE_APP_VERSION);

// function t(msg, params = {}) {
//   return store.state.t(msg, params);
// }

function PushAlert(msg) {
  // push_message 에서 번역적용됨
  store.commitRoot("push_message", msg);
}

function isFuture(m) {
  const now = moment();
  return m.diff(now) > 0;
}

async function waitAsync(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

//
async function postAsyncInner(data, more) {
  const url = process.env.VUE_APP_POST_URL;
  const axios = ax.create();
  return new Promise((resolve, reject) => {
    console.log("postAsyncInner start2", url);
    axios
      .post(url, data, more)
      .then((res) => {
        console.log("postAsyncInner end", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("postAsyncInner err", err);
        reject(err);
      });
  });
}

function getAgo(d) {
  let ago = d.fromNow(true);
  if (ago.indexOf("a few seconds") >= 0) {
    return "조금";
  } else if (ago.indexOf("a second") >= 0) {
    return "조금";
  } else if (ago.indexOf("a minute") >= 0) {
    return "조금";
  } else if (ago.indexOf("minutes") >= 0) {
    ago = ago.replace("minutes", "분");
  } else if (ago.indexOf("days") >= 0) {
    ago = ago.replace("days", "일");
  } else if (ago.indexOf("a day") >= 0) {
    ago = ago.replace("a day", "1일");
  } else if (ago.indexOf("day") >= 0) {
    ago = ago.replace("day", "일");
  }
  return ago;
}

//----------------------------
// store
//----------------------------
store = createStore({
  state: {
    darkmode: false,
    currentLanguage: "en",
    //
    show_header: true,
    //
    pd_contract: null,
    nft_list: [],
    login: null,
    // gas_limit: "",
    // fee_text: "? KLAY",
    // hatch_time: 0,
    detail_source: null,
    message_list: [],
    //
    ui_header: false,
    ui_potdak_loaded: false,
    is_ui_potdak: false,
    is_ui_farm: false,
    is_ui_hello: false,
    is_ui_doum: false,
    //
    t: null,
    onChangePage: () => {},
    onAccountChangeCallback: () => {
      return false;
    },
    klaytn: null,
    klaytn_logined: false,
    klaytn_enter_data: {
      target: "",
      fail: "",
      condition: () => {
        return false; // true 면 target 으로 간다.
      },
    },
    // login v2 (kaikas + metamask)
    lv2_show_wallet_login: false,
    lv2_login_doing: false,
    lv2_is_login: false,
    lv2_login_data: null,
    lv2_login_after: () => {},
    //
    exists_new_version: false,
  },
  getters: {
    currentLanguage: (state) => {
      return state.currentLanguage;
    },
    exists_new_version: (state) => {
      return state.exists_new_version;
    },
    pd_contract: (state) => {
      return state.pd_contract;
    },
    nft_list: (state) => {
      return state.nft_list;
    },
    login: (state) => {
      return state.login;
    },
    gas_limit: (state) => {
      return state.login.gas_limit;
    },
    fee_text: (state) => {
      return state.login.fee_text;
    },
    hatch_time_text: (state) => {
      // console.log("state.hatch_time", state.login.hatch_time);
      const d = moment().add(state.login.hatch_time);
      return getAgo(d);
    },
    detail_source: (state) => {
      return state.detail_source;
    },
    message_cur: (state) => {
      if (state.message_list.length < 1) return null;
      return state.message_list[0];
    },
    message_left: (state) => {
      return state.message_list.length - 1;
    },
    is_ui_farm: (state) => {
      return state.is_ui_farm;
    },
    is_ui_potdak: (state) => {
      return state.is_ui_potdak;
    },
    is_ui_hello: (state) => {
      return state.is_ui_hello;
    },
    is_ui_doum: (state) => {
      return state.is_ui_doum;
    },
    // klaytn: (state) => {
    //   return state.klaytn;
    // },
    klaytn_logined: (state) => {
      return state.klaytn_logined;
    },
  },
  mutations: {
    lv2_set_logout: (state) => {
      state.lv2_is_login = false;
      state.lv2_show_wallet_login = false;
      state.lv2_login_data = null;
      state.onAccountChangeCallback();
    },
    lv2_set_login: (state, data) => {
      state.lv2_is_login = true;
      state.lv2_show_wallet_login = false;
      state.lv2_login_data = data;
      state.onAccountChangeCallback = () => {
        return true;
      };
    },
    set_darkmode: (state, darkmode) => {
      console.log("set_darkmode", darkmode);

      state.darkmode = darkmode;
      localStorage.setItem("darkmode", darkmode ? "on" : "off");
    },
    set_header: (state, ui_header) => {
      state.ui_header = ui_header;
    },
    // data: {target, fail}
    set_enter_name: (state, data) => {
      console.log("set_enter_name", data);

      if (data.condition === undefined) {
        data.condition = function () {
          return true;
        };
      }

      if (data.onChangedAccount === undefined) {
        data.onChangedAccount = function () {};
      }

      state.klaytn_enter_data = data;
    },
    set_account_change_callback: (state, func) => {
      state.onAccountChangeCallback = func;
    },
    set_pd_first: (state, data) => {
      // console.log("set_pd_first", data);

      // if (state.klaytn != null) {
      //   return;
      // }

      state.onChangePage = data.onChangePage;

      const router = data.router;
      // const klaytn = data.klaytn;
      // state.klaytn = klaytn;
      state.t = data.t;
      state.i18n = data.i18n;

      if (window.ethereum !== undefined) {
        window.ethereum.on("accountsChanged", () => {
          console.log("ethereum.on: accountsChanged");
          state.onAccountChangeCallback();
        });
      }

      if (window.klaytn !== undefined) {
        window.klaytn.on("accountsChanged", async () => {
          console.log("klaytn.on: accountsChanged");

          if (state.onAccountChangeCallback()) {
            // true 면 내부에서 페이지 전환을 진행함
            return;
          }

          const data = state.klaytn_enter_data;
          data.onChangedAccount();

          if (state.klaytn_logined) {
            console.log("accountsChanged fail, klaytn_logined");
            PushAlert(store.t("#ERR_WALLET_CHANGED"));
            state.klaytn_logined = false;
            router.push(state.klaytn_enter_data.fail);
            return;
          }

          state.klaytn_enter_data = null;
          if (data.condition !== undefined) {
            if (!data.condition()) {
              console.log(
                "accountsChanged condition fail",
                state.klaytn_enter_data
              );
              router.push(data.fail);
              return;
            }
          }

          console.log("accountsChanged ok", data.target);
          state.klaytn_logined = true;
          if (data.target === null) {
            return;
          }

          router.push(data.target);
        });
      }
    },
    set_klaytn_logined: (state, b) => {
      state.klaytn_logined = b;
    },
    check_version: (state, version) => {
      state.exists_new_version = VERSION < version;
    },
    open_detail: (state, uid) => {
      const i = state.nft_list.findIndex((data) => {
        return data.uid === uid;
      });
      console.log("open_detail", uid, i);

      state.detail_source = state.nft_list[i];
      // console.log("open_detail found", i, state.detail_source);
    },
    pop_message: (state) => {
      state.message_list.shift();
    },
    push_message: (state, msg) => {
      const t_msg = state.t(msg);
      state.message_list.push(t_msg);
    },
    close_detail: (state) => {
      // console.log("close_detail");
      state.detail_source = null;
    },
    set_pd_contract: (state, contract) => {
      // console.log("set_pd_contract", contract);
      state.pd_contract = contract;
    },
    set_login: (state, data) => {
      state.login = data;
    },
    set_nft_list: (state, source_list) => {
      const list = [];
      // console.log("set_nft_list in");
      for (const i in source_list) {
        const source = source_list[i];
        const d = moment.duration(source.left_end, "seconds");
        const date_end = moment().add(d);
        // console.log("left_end", date_end);
        list.push({
          uid: source.uid,
          title: source.title,
          description: source.description,
          image: source.image,
          attrs: source.attrs,
          status: source.status,
          bg: source.bg,
          date_end,
          grade: source.grade,
          egg: source.egg,
        });
      }

      // test code
      // for (let i = 0; i < 10; i++) {
      //   list.push(list[0]);
      //   list[list.length - 1].uid = list.length;
      // }

      state.nft_list = list;
      console.log("set_nft_list im end");
    },
    set_nft: (state, source) => {
      const uid = source.uid;
      const i = state.nft_list.findIndex((data) => {
        if (data.uid != uid) return false;
        return true;
      });

      if (i < 0) {
        console.log("set_nft not found uid", uid, source);
        return;
      }

      const data = state.nft_list[i];
      console.log("set_nft found", source);

      if (source.image !== undefined) {
        data.image = source.image;
      }
      if (source.title !== undefined) {
        data.title = source.title;
      }
      if (source.description !== undefined) {
        data.description = source.description;
      }
      if (source.attrs !== undefined) {
        data.attrs = source.attrs;
      }
      if (source.status !== undefined) {
        data.status = source.status;
      }
      if (source.left_end !== undefined) {
        const d = moment.duration(source.left_end, "seconds");
        const m = moment().add(d);
        data.date_end = m;
      }
      if (source.grade !== undefined) {
        data.grade = source.grade;
      }
      if (source.egg !== undefined) {
        data.egg = source.egg;
      }
      if (source.bg !== undefined) {
        console.log("set bg", data.bg, source.bg);
        data.bg = source.bg;
      }
    },
    add_nft: (state, source) => {
      const uid = source.uid;
      const i = state.nft_list.findIndex((data) => {
        if (data.uid != uid) return false;
        return true;
      });

      const data = {};
      data.uid = source.uid;
      data.image = source.image;
      data.title = source.title;
      data.description = source.description;
      data.attrs = source.attrs;
      data.status = source.status;
      {
        const d = moment.duration(source.left_end, "seconds");
        const m = moment().add(d);
        data.date_end = m;
      }
      data.grade = source.grade;
      data.egg = source.egg;

      if (i < 0) {
        state.nft_list.push(data);
      } else {
        state.nft_list[i] = data;
      }
    },
    set_ui: (state, fullPath) => {
      console.log("set_ui", fullPath);

      state.is_ui_farm = false;
      state.is_ui_potdak = false;
      state.is_ui_hello = false;
      state.is_ui_doum = false;

      let changed = true;
      if (fullPath.indexOf("/pdak/farm") >= 0) {
        state.is_ui_farm = true;
      } else if (fullPath.indexOf("/pdak") >= 0) {
        state.is_ui_potdak = true;
        state.ui_potdak_loaded = true;
      } else if (fullPath.indexOf("/hello") >= 0) {
        state.is_ui_hello = true;
      } else if (fullPath.indexOf("/doum") >= 0) {
        state.is_ui_doum = true;
      } else {
        console.log("unknown set_ui", fullPath);
        changed = false;
      }

      if (changed) {
        state.ui_header.changedUI();
      }

      state.onChangePage();
    },
  },
  actions: {},
  modules: {},
});

store.waitAsync = waitAsync;

store.procExtraCmdsAsync = async (extra_cmds) => {
  if (!Array.isArray(extra_cmds.extra_cmds)) return;

  const cmds = extra_cmds.extra_cmds;
  console.log("procExtraCmds", cmds);

  cmds.forEach((cmd) => {
    if (cmd.exists_new_version !== undefined) {
      store.state.exists_new_version = cmd.exists_new_version;
      console.log("cmd.exists_new_version", cmd.exists_new_version);
    }
  });
};

store.getAsync = async (url) => {
  const axios = ax.create();
  return new Promise((resolve) => {
    axios
      .get(url)
      .then(async (res) => {
        console.log("getAsync req", url);
        console.log("getAsync res", res);
        await store.procExtraCmdsAsync(res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("getAsync req", url);
        console.log("getAsync err", err);
        resolve({
          code: -999,
          error_msg: "#ERR_NETWORK",
        });
      });
  });
};

let sending = false;
let access_token = null;
let post_last = moment();
store.postAsync = async (data) => {
  data.ver = VERSION;
  data.lang = store.state.i18n.locale;

  let wait_count = 0;
  while (sending) {
    await waitAsync(100);
    wait_count++;
    if (wait_count % 10) {
      console.log("postAsync wait", wait_count);
    }
  }

  sending = true;
  post_last = moment();

  try {
    const more = {};
    if (data.api != "login") {
      more.headers = { authorization: access_token };
    } else {
      access_token = null;
    }

    const res = await postAsyncInner(data, more);
    console.log("postAsync req", data);
    console.log("postAsync res", res);
    await store.procExtraCmdsAsync(res);

    sending = false;
    access_token = res.access_token;

    return res;
  } catch (err) {
    console.log("postAsync req", data);
    console.log("postAsync err", err.message);

    sending = false;
    access_token = null;

    return {
      code: -999,
      error_msg: "#ERR_NETWORK",
    };
  }
};

// 인증없는 통신
store.postAsyncSimple = (data) => {
  data.ver = VERSION;
  data.lang = store.state.i18n.locale;
  // console.log("POST_SIMPLE_URL", POST_SIMPLE_URL);

  const axios = ax.create();
  return new Promise((resolve) => {
    axios
      .post(POST_SIMPLE_URL, data)
      .then(async (res) => {
        console.log("postAsyncSimple", data);
        console.log("postAsyncSimple res", res);
        await store.procExtraCmdsAsync(res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("postAsyncSimple", data);
        console.log("postAsyncSimple err", err);
        resolve({
          code: -999,
          error_msg: "#ERR_NETWORK",
        });
      });
  });
};

store.postAsyncUpload = (call_name, data) => {
  const axios = ax.create();
  const URL = `${process.env.VUE_APP_GET_URL}${call_name}`;
  return new Promise((resolve) => {
    axios
      .post(URL, data)
      .then(async (res) => {
        console.log("postAsyncUpload req", call_name, data);
        console.log("postAsyncUpload res", res);
        await store.procExtraCmdsAsync(res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("postAsyncUpload req", call_name, data);
        console.log("postAsyncUpload err", err);
        resolve({
          code: -999,
          error_msg: "#ERR_NETWORK",
        });
      });
  });
};

// 주서 넣는 방식
// call_name = '/rtzapi'
store.postAsyncNamed = (call_name, data) => {
  data.ver = VERSION;
  data.lang = store.state.i18n.locale;

  const axios = ax.create();
  const URL = `${process.env.VUE_APP_GET_URL}${call_name}`;
  return new Promise((resolve) => {
    axios
      .post(URL, data)
      .then(async (res) => {
        console.log("postAsyncNamed", call_name, data);
        console.log("postAsyncNamed res", res);
        await store.procExtraCmdsAsync(res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("postAsyncNamed", call_name, data);
        console.log("postAsyncNamed err", err);
        resolve({
          code: -999,
          error_msg: "#ERR_NETWORK",
        });
      });
  });
};

store.Alert = (msg) => {
  // push_message 에서 번역적용됨
  // console.log("Alert", msg);
  store.commitRoot("push_message", msg);
};

store.AlertPost = (res) => {
  console.log("AlertPost res", res);

  if (res == null) {
    store.commitRoot("push_message", "#ERR_NETWORK");
    return;
  }

  if (res.error_msg === undefined) {
    store.commitRoot("push_message", "#ERR_SERVER_DEFAULT");
    return;
  }

  store.commitRoot("push_message", res.error_msg);
};

store.sendLog = (msg) => {
  console.log("sendLog", msg);
  // TODO : 서버로전송
};

store.getEggImage = (source, no) => {
  if (source.grade < no) {
    // return "/img/pdak/empty.png";
    return "/img/pdak-v2/empty.png";
  }

  let left = source.grade - source.egg;
  if (left >= no) {
    // return "/img/pdak/egg-1.png";
    return "/img/pdak-v2/egg-1.png";
  }

  // return "/img/pdak/egg-empty.png";
  return "/img/pdak-v2/egg-hatched.png";
};

store.getStatusText = (source) => {
  const t = store.t;
  if (source.status == 0) {
    return t("#STATUS_NO_EGG");
  } else if (source.status == 1) {
    if (isFuture(source.date_end)) {
      const ago = getAgo(source.date_end);
      return t("#STATUS_HATCHING", { ago });
    }
    return t("#STATUS_HATCHING_COMPLETE");
  } else if (source.status == 2) {
    return t("#STATUS_NEED_COMPIRM");
  } else if (source.status == 3) {
    if (source.egg < source.grade) {
      // const left = source.grade - source.egg;
      if (isFuture(source.date_end)) {
        const ago = getAgo(source.date_end);
        return t("#STATUS_TAKING_EGG", { ago });
      }
      return t("#STATUS_TAKE_EGG_COMPLETE");
    }
    return "";
  }
  return "";
};

// 알 부화하기 : 알에서 시간이 다 되서 닭으로 변경 가능
store.canHatchEgg = (source) => {
  if (source.status != 1) return false;
  if (isFuture(source.date_end)) return false;
  return true;
};
store.canHatchEggClass = (source) => {
  if (store.canHatchEgg(source)) {
    return "tw-detail-btn-on";
  }
  return "tw-detail-btn-off";
};

// 닭 확정 : 알에서 나온 닭일때 가능
store.canTakeDak = (source) => {
  return source.status == 2;
};
store.canTakeDakClass = (source) => {
  if (store.canTakeDak(source)) {
    return "tw-detail-btn-on";
  }
  return "tw-detail-btn-off";
};

store.nftBgStyle = (source) => {
  let ret = "";
  if (source.bg) {
    ret = `background-color: #${source.bg};`;
  }
  console.log("nftBgStyle", source.bg);
  return ret;
};

// 팟 구매 : 알 생산되어 잇을 경우에 가능
store.canTakeEgg = (source) => {
  if (source.status != 3) return false;
  if (source.egg >= source.grade) return false;
  if (isFuture(source.date_end)) return false;
  return true;
};
store.canTakeEggClass = (source) => {
  if (store.canTakeEgg(source)) {
    return "tw-detail-btn-on";
  }
  return "tw-detail-btn-off";
};

// unknown local mutation type 문제로 root: true 옵션이 필요해서 만듬
// https://im-designloper.tistory.com/56
store.commitRoot = (name, payload) => {
  store.commit(name, payload, { root: true });
};

store.mdt = (key, params = {}) => {
  return marked(store.state.t(key, params));
};

store.t = (key, params = {}) => {
  return store.state.t(key, params);
};

// 배경색 때문에 크게 3가지로 분류함
store.page_type = (to) => {
  if (
    to == "/" ||
    to.indexOf("/hello") == 0 ||
    to.indexOf("/test") == 0 ||
    to.indexOf("/about") == 0
  ) {
    return 0;
  } else if (
    to.indexOf("/pdak") == 0 ||
    to.indexOf("/pdview") == 0 ||
    to.indexOf("/pdak/farm") == 0
  ) {
    return 1;
  } else if (
    to.indexOf("/doum") == 0 ||
    to.indexOf("/rtz") == 0 ||
    to.indexOf("/my_tools") == 0 ||
    to.indexOf("/opensea_tools") == 0
  ) {
    return 2;
  }

  console.log("page_type fail unknown menu", to);
  return -1;
};

store.isAdminAddr = (addr) => {
  const list = [
    "0x941a7a3a0b9B63D23d245e55CEFc593AE0a63290",
    "0x65EfC5ce9D6aB663365ac5452f52168f227a5d93",
  ];

  for (let i = 0; i < list.length; i++) {
    const a = list[i];
    if (a.toLowerCase() == addr.toLowerCase()) {
      return true;
    }
    console.log(`not match ${i}, [${list[i]}]`);
  }
  return false;
};

store.isRtzAddr = (addr) => {
  const list = [
    // "0x05b5da062B5FCc0A84b4A67983b2a795C90d20ad", // 카이카스 복붙
    "0x05b5da062b5fcc0a84b4a67983b2a795c90d20ad", // 웹꺼 복붙
    "0x941a7a3a0b9b63d23d245e55cefc593ae0a63290", // cypress-main
    "0x65EfC5ce9D6aB663365ac5452f52168f227a5d93", // baobab-main
    "0x1e0A33d97f7793035704F5B0d896c631fb47BbE4", // baobab-sub
    "0x439f2c22613e8fe8a52568c9ea4a2f8ec8fde1b6", // kaikas account-1
    "0x0277d0F092aA178D575A06155D8541e516992256", // ttigerz
  ];

  for (let i = 0; i < list.length; i++) {
    const a = list[i];
    if (a.toLowerCase() == addr.toLowerCase()) {
      return true;
    }
    console.log(`not match ${i}, [${list[i]}]`);
  }
  return false;
};

// wallet
import WEB3 from "web3";

let wallet_cache = {
  type: "",
};

store.get_wallet_app = (app_name) => {
  if (app_name === undefined) {
    app_name = wallet_cache.type;
  }

  if (app_name == "metamask") {
    if (wallet_cache.type != "metamask") {
      wallet_cache = {
        type: "metamask",
      };
    }
    return window.ethereum;
  } else if (app_name == "kaikas") {
    wallet_cache = {
      type: "kaikas",
    };
    return window.klaytn;
  }

  wallet_cache = {
    type: "",
  };

  throw Error(["get_wallet_app fail", app_name, wallet_cache]);
};

store.get_wallet_addr = async (wallet_input = null) => {
  let wallet = wallet_input;
  if (wallet === null) {
    wallet = store.get_wallet_app();
  }
  console.log("get_wallet_addr", wallet);

  const accounts = await wallet.enable();
  console.log("get_wallet_addr accounts", accounts);
  if (Array.isArray(accounts) && accounts.length > 0) {
    return accounts[0];
  }

  return null;
};

// 외부에는 노출 시키지 않음
function get_wallet_contract_class(wallet_input = null) {
  let wallet = wallet_input;
  if (wallet === null) {
    wallet = store.get_wallet_app();
  }

  if (wallet_cache.type == "metamask") {
    // console.log("get_wallet_contract_class wallet_cache", wallet_cache);
    if (wallet_cache.web3 === undefined) {
      const web3 = new WEB3(wallet);
      wallet_cache.web3 = web3;
    }
    return wallet_cache.web3.eth.Contract;
  } else if (wallet_cache.type == "kaikas") {
    if (wallet_cache.web3 === undefined) {
      const web3 = new Caver(wallet);
      wallet_cache.web3 = web3;
    }
    return wallet_cache.web3.klay.Contract;
  }
  throw Error(["get_wallet_conect_class fail", wallet_cache]);
}

store.get_contract = (CONTRACT) => {
  let wallet = store.get_wallet_app();

  const networkVersion = wallet.networkVersion;
  console.log("networkVersion", networkVersion);
  const address = CONTRACT.networks[networkVersion].address;
  console.log("contract address", address);

  const Web3Contract = get_wallet_contract_class();
  const contract = new Web3Contract(CONTRACT.abi, address);
  return contract;
};

store.get_opensea_url = (uid) => {
  // v83
  // return `https://opensea.io/assets/klaytn/0x371eaa62e25e0ceb346925dfdfb77eeec14b98ff/${uid}`;
  // v90
  return `https://opensea.io/assets/klaytn/0x9aec39b18da65062e2d266b541cd41a6da30db9d/${uid}`;
};

//---------------
// store value
//---------------
store.isFuture = isFuture;
store.isFirstHeaderMount = true;
store.VERSION = VERSION;
store.DISPLAY_URL = DISPLAY_URL;
store.GET_URL = GET_URL;
store.IS_PRODUCTION = IS_PRODUCTION;

store.ITEM_BG_SID = "bg-gray-300 dark:bg-gray-600";
store.ITEM_BG_PDAK = "bg-yellow-300 dark:bg-yellow-800";
store.ITEM_BG_DOUM = "bg-indigo-200 dark:bg-indigo-600";

export default store;
//---------------
// store END
//---------------

// 로그인 유지
setInterval(() => {
  if (access_token === null) {
    return;
  }

  const delta = moment().diff(post_last) / 1000;
  if (delta < HEARTBEAT_SEC) {
    return;
  }

  // 15분 지나면 한번 통신
  store.postAsync({ api: "hb" });
}, HEARTBEAT_CHECK_MS);
