export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "#ERR_NETWORK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a problem with communication with the server."])},
  "#ERR_SERVER_DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a problem with server processing."])},
  "#ERR_CANNOT_FARM_ENTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farm is under construction."])},
  "#ERR_NEED_RECONNECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server setting has been changed.  \nPlease refresh page."])},
  "#ERR_SERVER_BLOCK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This function is checking now."])},
  "#ERR_NEED_KAIKAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need a Kaikas wallet"])},
  "#ERR_WALLET_CHANGED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet account change was detected."])},
  "#ERR_CONTRACT_NETWORK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the network of your wallet."])},
  "#ERR_DOUM_DESIGNER_ONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This wallet is not allowed."])},
  "#ERR_REGISTERED_WALLET_ONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This wallet is not registered"])},
  "#ERR_NOT_FOUND_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found result.."])},
  "#ERR_PD_EGG_NEED_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It takes time to complete."])},
  "#ERR_PD_TAKE_DAK_STATUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a problem with the display status."])},
  "#ERR_PD_MINT_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minting request failed."])},
  "#ERR_PD_MINT_AFTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minting has been completed, but it seems to take time to reflect it on the blockchain.\nPlease reconnect a little later."])},
  "#ERR_NEED_PD_NFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please visit with PlantosyDak."])},
  "#ERR_DISCORD_NOT_FOUND_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couldn't find the data. Please proceed from the beginning."])},
  "#LOADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOADING"])},
  "#LOADING_WAIT_WALLET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for my wallet authentication."])},
  "#NEW_VERSION_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There's a new version page. Please refresh the page."])},
  "#MENU_HELLO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "#MENU_HELLO_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greeting"])},
  "#MENU_HELLO_TITLE_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greeting. This is SIDNFT"])},
  "#MENU_PDAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT"])},
  "#MENU_PDAK_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PlantosyDak"])},
  "#MENU_PDAK_VIEWER_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PlantosyDak Viewer"])},
  "#MENU_PDAK_FARM_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PlantosyDak Farm"])},
  "#MENU_DOUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUPPORT"])},
  "#MENU_DOUM_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREATOR SUPPORT"])},
  "#RTZ_VIEW_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RocketZett's Viewer"])},
  "#RTZ_WORK_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RocketZett's Workspace"])},
  "#RTZ_NFT_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RocketZett NFT"])},
  "#OPENSEA_TOOLS_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenSea Tools"])},
  "#MY_TOOLS_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Tools"])},
  "#TEST_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEST"])},
  "#DISCORD_OAUTH2_VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
  "#DISCORD_OAUTH2_VIEW_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord Connect Wallet"])},
  "#INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "#WALLET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
  "#HATCH_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatching Time"])},
  "#HATCH_FEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatching Fee"])},
  "#OWNED_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory"])},
  "#FUNCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functions"])},
  "#BG_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background"])},
  "#HATCH_COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatched"])},
  "#CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comfirm"])},
  "#BACK_TO_EGG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the egg."])},
  "#TAKE_EGG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take the egg"])},
  "#FEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
  "#EXTERNAL_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External link"])},
  "#TIP_HATCH_EGG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dak comes out of the egg. Has random attributes. Pot does not change."])},
  "#TIP_CONFIRM_DAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After confirmation, the chicken begins to make eggs. If there are any eggs left to lay."])},
  "#TIP_BACK_TO_EGG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you don't like the Dak, you can go back to the egg."])},
  "#TIP_TAKE_EGG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After minting, you receive new eggs."])},
  "#STATUS_NO_EGG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more egg"])},
  "#STATUS_HATCHING": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hatching Now, ", _interpolate(_named("age")), " left"])},
  "#STATUS_HATCHING_COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatch is done"])},
  "#STATUS_NEED_COMPIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirmation."])},
  "#STATUS_TAKING_EGG": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Making eggs. ", _interpolate(_named("ago")), " left."])},
  "#STATUS_TAKE_EGG_COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can take eggs."])},
  "#LANG_KO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한"])},
  "#LANG_EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
  "#NUMBER_ONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "#ATTRIBUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attributes"])},
  "#BTN_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])},
  "#BTN_EXIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXIT"])},
  "#NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOTICE"])},
  "#WALLET_LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIN"])},
  "#WALLET_LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGOUT"])},
  "#HELLO_MSG_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# SIDNFT is focusing on producing NFT content.\nNow <del> Ethereum is too expensive</del> We are developing NFT contents with Klaytn.  \n<br/>\nIn NFT, the function of exchange is very important. This function means that users can use the rights of content operators (developers). Users can use the content more actively.  \n<br/>\n**Virtualization** is currently underway everywhere. Humanity is facing a major crisis due to environmental pollution. If **excess production** is performed as in the past, **environmental pollution** will increase. If mankind eats in unison, produces less, and consumes less, this crisis can be easily resolved, but this method will not last. \nSo if we introduce more virtualization, consumption will help the environment and satisfy humanity without reducing it.\nI think this is **Metaverse**.\n"])},
  "#RELATED_NEWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related News"])},
  "#TAG_PRODUCTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productivity"])},
  "#TAG_RECYCLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recycling"])},
  "#TAG_VIRTUALIZATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtualization"])},
  "#PDAK_MSG_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["## PlantosyDak is NFT that combine pots and chickens.\n\nIt is being developed for the purpose of being used as an ornament in a metaverse environment.  \n<br/>\nFlower pots symbolize the environment. Chicken symbolizes animals in the metaverse. Even if it is difficult to raise animals in the real world, they will grow safely and well."])},
  "#PDAK_ENTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter  \n<sub>Only the holder</sub>  \n<sub>Need kaikas wallet</sub>"])},
  "#PDAK_MSG_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["## NFT that lays eggs.\n\nPlantosyDak lay eggs to increase the number of eggs they hold. The additional NFT issued is also the owner's and can be traded through OpenSea.  \n<br/>\nPlantosyDak hatched from eggs are one less than their parents' maximum knowledge.  \n<br/>\nIn the early stages of development, it is supplied cheaply and the fee for egg hatching is also low. As the development is completed, the fee increases and the amount of PlantosyDak will be adjusted."])},
  "#PDAK_MSG_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["## It will be upgraded.\n\nCurrently, I drew it myself for the opening.\nIn the future, we plan to upgrade the painting step by step through the designer.\nAfter the update, PlantosyDak holders can upgrade their NFTs using the [**Enter**] button. Of course, the old version is also available.  \n<br/>\n+ Present. - 32x32 images.\n+ Future - Higher resolution applied.\n+ More Future - 3D Modeling."])},
  "#PDAK_MSG_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["## can edit description\n\nYou can edit the description of the NFT.\nLeave a message and brag about it on OpenSea."])},
  "#PDAK_MSG_WEBGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["### Web Game Demo\n\nPlayable demo in your web browser\n"])},
  "#PDAK_MSG_WEBGAM_KABOOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D run game.  \nCan play mobile web browser.  \n(also iOS)\nRun and collect coins."])},
  "#PDAK_MSG_WEBGAM_UNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D mini game.  \nCan play pc web browser.  \n(only PC Desktop) \nTake the chicken from the pot."])},
  "#DOUM_H1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# We support creators. (Planning)"])},
  "#DOUM_ENTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter <sub>(only the registered)</sub>"])},
  "#DOUM_MSG_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The core of NFT is that the value of the creation goes directly to the creator. I don't think it's right to learn smart contracts or coding.  \n<br/>\nIf you want to do creative activities with NFT, but it's hard to learn coding, I'll help you.\nI'm busy with creative activities, but learning coding... Time can be wasted.  \n<br/>\nAdditionally, the development environment of smart contracts is a time of chaos.\nYou should also think about the problem of wasting time learning as the development environment is updated.  \n<br/>\nThe development environment is easier to learn if you start after stabilization.\n"])},
  "#PDVIEW_PARENT_EGG": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["**#", _interpolate(_named("uid")), "** 's No. ", _interpolate(_named("egg_uid")), " Egg"])},
  "#TOOL_CONVERT_DATE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenSea: Converting the date to value (number)"])},
  "#TOOL_CONVERT_DATE_DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When using the property value of OpenSea, the date...\nIf used, it needs to be converted into numbers.\n<br/>\nIf you enter it in DATE, VALUE is converted."])},
  "#AD_HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's an advertisement for operating funds."])}
}