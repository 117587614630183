import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index.js";

function make_title(key) {
  const title = process.env.VUE_APP_TITLE + " - " + store.t(key);
  return title;
}

const routes = [
  { path: "/", redirect: "/hello" },

  {
    path: "/hello",
    // name: "HomeSidNft",
    component: () => import("../views/HomeSidNft.vue"),
    meta: {
      title: "SIDNFT",
    },
    beforeEnter: (to, _, next) => {
      const key = "#MENU_HELLO";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },

  {
    path: "/hello/about",
    // name: "AboutPage",
    component: () => import("../views/AboutPage.vue"),
    meta: {
      title: "About",
    },
    beforeEnter: (to, _, next) => {
      const key = "#MENU_HELLO_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },

  {
    path: "/hello/projects",
    // name: "ProjectsPage",
    component: () => import("../views/SidProjectsPage.vue"),
    meta: {
      title: "About",
    },
    beforeEnter: (to, _, next) => {
      const key = "#MENU_HELLO_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },
  //-------------
  // pdak
  //-------------
  {
    path: "/pdak",
    // name: "PotDakPage",
    component: () => import("../views/PotDakPage.vue"),
    meta: {
      title: "심는닭",
    },
    beforeEnter: (to, _, next) => {
      const key = "#MENU_PDAK_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },

  {
    path: "/pdak/farm",
    // name: "PotDakFarm",
    component: () => import("../views/PotDakFarm.vue"),
    meta: {
      title: "닭장",
    },
    beforeEnter: (to, _, next) => {
      const key = "#MENU_PDAK_FARM_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },

  // 삭제예정
  {
    path: "/pdview",
    // name: "PotDakView",
    component: () => import("../views/PotDakView.vue"),
    meta: {
      title: "심는닭 뷰어",
    },
    beforeEnter: (to, _, next) => {
      const key = "#MENU_PDAK_VIEWER_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },
  // 삭제예정
  {
    path: "/pdview/:uid",
    // name: "PotDakViewUid",
    component: () => import("../views/PotDakView.vue"),
    meta: {
      title: "심는닭 뷰어",
    },
    beforeEnter: (to, _, next) => {
      const key = "#MENU_PDAK_VIEWER_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },

  {
    path: "/pdak/view",
    // name: "PotDakView",
    component: () => import("../views/PotDakView.vue"),
    meta: {
      title: "심는닭 뷰어",
    },
    beforeEnter: (to, _, next) => {
      const key = "#MENU_PDAK_VIEWER_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },
  {
    path: "/pdak/view/:uid",
    // name: "PotDakViewUid",
    component: () => import("../views/PotDakView.vue"),
    meta: {
      title: "심는닭 뷰어",
    },
    beforeEnter: (to, _, next) => {
      const key = "#MENU_PDAK_VIEWER_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },

  //-------------
  // doum
  //-------------
  {
    path: "/doum",
    // name: "DoumPage",
    component: () => import("../views/DoumPage.vue"),
    meta: {
      title: "",
    },
    beforeEnter: (to, _, next) => {
      const key = "#MENU_DOUM_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },
  // 삭제예정
  {
    path: "/rtzwork",
    // name: "RtzWorkPage",
    component: () => import("../views/RtzWorkPage.vue"),
    meta: {
      title: "RocketZett Workspace",
    },
    beforeEnter: (to, _, next) => {
      const key = "#RTZ_WORK_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },
  // 삭제예정
  {
    path: "/rtzview",
    // name: "RtzViewPage",
    component: () => import("../views/RtzViewPage.vue"),
    meta: {
      title: "RocketZett Viewer",
    },
    beforeEnter: (to, _, next) => {
      const key = "#RTZ_VIEW_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },
  // 삭제예정
  {
    path: "/rtznft",
    // name: "RtznftPage",
    component: () => import("../views/RtzNftPage.vue"),
    meta: {
      title: "RocketZett NFT",
    },
    beforeEnter: (to, _, next) => {
      const key = "#RTZ_VIEW_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },
  // 삭제예정
  {
    path: "/opensea_tools",
    // name: "OpenSeaTools",
    component: () => import("../views/OpenSeaTools.vue"),
    meta: {
      title: "툴페이지",
    },
    beforeEnter: (to, _, next) => {
      const key = "#RTZ_VIEW_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },
  {
    path: "/doum/tools",
    // name: "OpenSeaTools",
    component: () => import("../views/OpenSeaTools.vue"),
    meta: {
      title: "툴페이지",
    },
    beforeEnter: (to, _, next) => {
      const key = "#RTZ_VIEW_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },
  {
    path: "/doum/coding_tutorial",
    // name: "OpenSeaTools",
    component: () => import("../views/DoumCodingTutorialPage.vue"),
    meta: {
      title: "코딩 자습서",
    },
    beforeEnter: (to, _, next) => {
      const key = "#RTZ_VIEW_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },

  {
    path: "/dicord_oauth2",
    // name: "DiscordOAuth2",
    component: () => import("../views/DiscordTools.vue"),
    meta: {
      title: "디스코드 지갑연결",
    },
    beforeEnter: (to, _, next) => {
      const key = "#DISCORD_OAUTH2_VIEW_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },

  {
    path: "/my_tools",
    // name: "MyTools",
    component: () => import("../views/MyTools.vue"),
    meta: {
      title: "마이툴",
    },
    beforeEnter: (to, _, next) => {
      const key = "#OPENSEA_TOOLS_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },

  {
    path: "/doum/tools",
    // name: "DoumTools",
    component: () => import("../views/NotReadyPage.vue"),
    meta: {
      title: "마이툴",
    },
    beforeEnter: (to, _, next) => {
      const key = "#OPENSEA_TOOLS_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },

  //-----------------
  // test
  //-----------------
  {
    path: "/test",
    // name: "TestPage",
    component: () => import("../views/TestPage.vue"),
    meta: {
      title: "테스트",
    },
    beforeEnter: (to, _, next) => {
      const key = "#TEST_LONG";
      const title = make_title(key);
      to.meta.title = title;
      document.title = title;
      document.title_key = key;
      next();
    },
  },

  // {
  //   path: '/clientes/nuevo',
  //   name: 'CustomersNew',
  //   beforeEnter: (to, from, next) => {
  //        Auth(to, from, next);
  //        CheckPermission(to, from, next, "customers@add_customer");
  //   },
  //   component: CustomersNew,
  //   meta: {
  //       breadcrumb: {
  //           title: "key.to.translate.as.string',
  //           links: [ "Customers", "new"]
  //       }
  //   }
  //   }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  document.title = `${to.meta.title}`;
});

export default router;
